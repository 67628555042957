<template>
  <div>
    <PickupStep1
      v-if="pickupStep === 1"
      :selected="selected"
      @onUpdate="onUpdate"
    />
    <PickupStep2
      v-else-if="pickupStep === 2"
      :selected="selected"
      @onUpdate="onUpdate"
    />
    <PickupStep3
      v-else-if="pickupStep === 3"
      :selected="selected"
      @onUpdate="onUpdate"
    />
    <PickupStep4
      v-else-if="pickupStep === 4"
      :selected="selected"
      @onUpdate="onUpdate"
    />

    <v-fab-transition v-if="selected.id_warehouse">
      <v-btn
        small
        dark
        fixed
        bottom
        right
        fab
        color="warning"
        @click="showDialogGoodsNoLocation"
      >
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-dialog v-model="goodsNoLocationDialog" max-width="800px">
      <v-card>
        <v-card-title class="mb-3">
          <span class="text-h6">{{ $t("labels.empty_location_product") }}</span>
        </v-card-title>
        <v-card-text>
          <v-simple-table class="table-padding-2">
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th class="text-center">{{ $t("labels.sku") }}</th>
                  <th class="text-center">{{ $t("labels.barcode") }}</th>
                  <th class="text-center">{{ $t("labels.name") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(gn, gi) in goodsNoLocations"
                  :key="`gn_${gi}_${gn.sku}`"
                >
                  <td class="text-center">
                    <SkuDownload
                      :item="{ ...gn, id_warehouse: selected.id_warehouse }"
                    />
                  </td>
                  <td class="text-center">{{ gn.customer_goods_barcode }}</td>
                  <td class="text-center">{{ gn.name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="lastPickedDialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="mb-3">
          <span class="text-h6">{{ $t("labels.last_picked") }}</span>
          <v-spacer></v-spacer>
          <v-btn small color="error" @click="hideLastPicked">
            {{ $t("labels.close") }}
          </v-btn>
        </v-card-title>
        <v-card-text class="text-center">
          <v-alert color="error" text dark class="text-h2 mb-0">
            {{ lastPicked.cell_id }}
          </v-alert>
          <v-alert color="green" text dark class="text-h2 box-quantity mb-0">
            {{ lastPicked.picked }}
          </v-alert>
          <v-alert color="secondary" text dark class="text-h4">
            <span class="text-uppercase">{{ $t("labels.basket") }}: </span>
            {{ lastPicked.basket_index }} [{{ lastPicked.basket_code }}]
          </v-alert>

          <div class="font-weight-medium text-center fs-13">
            {{ $t("labels.sku") }}: {{ hideString(lastPicked.goods_sku, 2) }} -
            {{ $t("labels.barcode") }}:
            {{ hideString(lastPicked.goods_barcode, 3) }}
          </div>
          <div class="text-center fs-14">{{ lastPicked.goods_name }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { hideString } from "@/libs/helpers";

export default {
  components: {
    PickupStep1: () => import("@/components/goods_issue/PickupStep1"),
    PickupStep2: () => import("@/components/goods_issue/PickupStep2"),
    PickupStep3: () => import("@/components/goods_issue/PickupStep3"),
    PickupStep4: () => import("@/components/goods_issue/PickupStep4"),
    SkuDownload: () => import("@/components/goods/SkuDownload"),
  },
  name: "Pickup",
  data: () => ({
    selected: {},
    goodsNoLocationDialog: false,
    lastPickedDialog: false,
    lastPicked: {},
    goodsNoLocations: [],
  }),
  computed: {
    pickupStep() {
      if (!this.selected.id_warehouse) {
        return 1;
      } else if (
        !this.selected.pickup_type &&
        this.selected.pickup_type !== 0 &&
        (!this.selected.order_ids || this.selected.order_ids.length === 0)
      ) {
        return 2;
      } else if (!this.selected.id_command) {
        return 3;
      } else {
        return 4;
      }
    },
  },
  methods: {
    hideString,
    getGoodsNoLocation() {
      httpClient
        .post("/goods/v1/get-wait-pickup-no-location", {
          id_warehouse: this.selected.id_warehouse,
        })
        .then(({ data }) => {
          this.goodsNoLocations = [...data];
        });
    },
    showDialogGoodsNoLocation() {
      this.goodsNoLocationDialog = true;
      this.goodsNoLocations = [];
      this.getGoodsNoLocation();
    },
    hideDialogGoodsNoLocation() {
      this.goodsNoLocationDialog = false;
    },
    onUpdate(data) {
      if (data.last_picked) {
        this.lastPicked = { ...data.last_picked };
        this.lastPickedDialog = true;
        delete data.last_picked;
      }
      this.selected = { ...data };
    },
    hideLastPicked() {
      this.lastPicked = {};
      this.lastPickedDialog = false;
    },
    async getActiveCommand() {
      if (this.isLoading) {
        this.isLoading = false;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-issue-detail/v1/pickup-get-active-command"
        );
        this.selected = {
          ...this.selected,
          id_warehouse: data.id_warehouse,
          process_type: data.process_type,
          pickup_type: data.command_type,
          id_command: data.id,
        };
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },

    listenSocket() {
      window.socket.on("pickup_command_id", (data) => {
        if (data.id) {
          this.getActiveCommand();
        }
      });
      window.socket.on("pickup_command_create_failed", (data) => {
        if (data.content) {
          this.$vToastify.error(data.content);
          document.getElementById("error_sound_player").play();
        }
      });
      window.socket.on("pickup_command_created_warehouse", (data) => {
        if (
          data.id_warehouse &&
          this.selected.id_warehouse &&
          this.selected.id_warehouse == data.id_warehouse
        ) {
          if (this.pickupStep === 2) {
            this.$root.$emit("onPickupUpdated");
          }
          /*else if (this.pickupStep === 3) {
            this.$root.$emit('onPickupUpdated')
          }*/
        }
      });
    },
  },
  mounted() {
    this.getActiveCommand();
    this.listenSocket();
  },
};
</script>

<style scoped></style>
